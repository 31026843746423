import { store } from "@/store/store";
import { objetivo } from "@/shared/dtos/objetivo";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "objetivoModule",
  store,
  dynamic: true,
})
class objetivoModule extends VuexModule {
  public objetivos: objetivo[] = [];
  public objetivo: objetivo = new objetivo();

  @Action({ commit: "onGetobjetivos" })
  public async getobjetivos() {
    return await ssmHttpService.get(API.objetivo);
  }

  @Action({ commit: "onGetobjetivo" })
  public async getobjetivo(id: any) {
    return await ssmHttpService.get(API.objetivo + "/" + id);
  }

  @Action
  public async guardarobjetivo(objetivo: objetivo) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.objetivo, objetivo.toJson());
  }

  @Action
  public async modificarobjetivo(objetivo: objetivo) {
    return await ssmHttpService.put(API.objetivo + "/" + objetivo.id, objetivo);
  }

  @Action
  public async eliminarobjetivo(objetivo: objetivo) {
    return await ssmHttpService.delete(
      API.objetivo + "/" + objetivo.id,
      null,
      false
    );
  }

  @Mutation
  public onGetobjetivos(res: objetivo[]) {
    this.objetivos = res ? res.map((x) => new objetivo(x)) : [];
  }

  @Mutation
  public onGetobjetivo(res: objetivo) {
    this.objetivo = new objetivo(res);
  }
}
export default getModule(objetivoModule);
