import { store } from '@/store/store';
import { kpis_actuacion } from '@/shared/dtos/kpis_actuacion';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'kpis_actuacionModule',
    store,
    dynamic: true
})
class kpis_actuacionModule extends VuexModule {
    public kpis_actuaciones: kpis_actuacion[] = [];
    public kpis_actuacion: kpis_actuacion = new kpis_actuacion();

    @Action({ commit: 'onGetkpis_actuaciones' })
    public async getkpis_actuaciones() {
        return await ssmHttpService.get(API.kpis_actuacion);
    }

    @Action({ commit: 'onGetkpis_actuacion' })
    public async getkpis_actuacion(id: any) {
        return await ssmHttpService.get(API.kpis_actuacion + '/' + id);
    }

    @Action
    public async guardarkpis_actuacion(kpis_actuacion: kpis_actuacion) {
       return await ssmHttpService.post(API.kpis_actuacion, kpis_actuacion.toJson());
    }

    @Action
    public async modificarkpis_actuacion(kpis_actuacion: kpis_actuacion) {
        return await ssmHttpService.put(API.kpis_actuacion + '/' + kpis_actuacion.id, kpis_actuacion);
    }

    @Action
    public async eliminarkpis_actuacion(kpis_actuacion: kpis_actuacion) {
       return await ssmHttpService.delete(API.kpis_actuacion + '/' + kpis_actuacion.id, null, false);
    }

    @Mutation
    public onGetkpis_actuaciones(res: kpis_actuacion[]) {
        this.kpis_actuaciones = res ? res.map((x) => new kpis_actuacion(x)) : []
    }

    @Mutation
    public onGetkpis_actuacion(res: kpis_actuacion) {
        this.kpis_actuacion = new kpis_actuacion(res);
    }

}
export default getModule(kpis_actuacionModule);